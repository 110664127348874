"use client";

import { Cannabis } from "lucide-react";
import Image, { ImageProps } from "next/image";
import React, { useState } from "react";

interface FallbackImageProps extends Omit<ImageProps, "src" | "alt"> {
    primarySrc: string | null;
    fallbackSrc: string | null;
    className?: string;
    alt: string;
}

const FallbackImage: React.FC<FallbackImageProps> = ({
    primarySrc,
    fallbackSrc,
    alt,
    className = "",
    ...imageProps
}) => {
    const [error, setError] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);

    // this is so dumb but it happens
    if (primarySrc === "undefined") {
        primarySrc = null;
    }

    const imageSrc = primarySrc || fallbackSrc;

    if (!primarySrc && !fallbackSrc) {
        return (
            <div className="relative h-full w-full">
                <div className="absolute inset-0 flex items-center justify-center">
                    <Cannabis className="h-12 w-12 text-gray-300" />
                </div>
            </div>
        );
    }

    return (
        <div className="relative h-full w-full object-contain">
            <div
                className={`
                  absolute inset-0 flex items-center justify-center
                  transition-opacity duration-300 ease-in-out
                  ${imageLoaded ? "pointer-events-none opacity-0" : "opacity-100"}
                `}
            >
                <Cannabis className="h-12 w-12 text-gray-300" />
            </div>

            {imageSrc && (
                <Image
                    {...imageProps}
                    src={error && fallbackSrc ? fallbackSrc : imageSrc}
                    alt={alt}
                    fill={true}
                    className={`
                        object-contain ${className}
                        transition-opacity duration-300 ease-in-out
                        ${imageLoaded ? "opacity-100" : "opacity-0"}
                    `}
                    onError={() => {
                        setError(true);
                        if (!fallbackSrc) setImageLoaded(false);
                    }}
                    onLoad={() => setImageLoaded(true)}
                />
            )}
        </div>
    );
};

export default FallbackImage;
