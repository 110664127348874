import CartPriceDisplay from "@/components/features/cart-edit/cart-price-display";
import CaseUnitInput from "@/components/features/cart-edit/case-unit-input";
import { changePromotionOnTransactions } from "@/components/features/cart-modal/actions";
import { Badge } from "@/components/shared/ui/badge";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@/components/shared/ui/select";
import { ShopCartTransaction } from "@/lib/cart/cart-types";
import { ManualPromotion } from "@/lib/shop/shop-types";
import { useCartStore } from "@/state-management/stores/cart-store";
import { useState } from "react";
import { useFormState } from "react-dom";
import { DeleteItemButton } from "./delete-item-button";

export default function CartTransactionDisplay({
    transaction,
    manualPromotionsList,
    inUsePromotions,
    hasBaseTransaction,
    backorderUnits = 0
}: {
    transaction: ShopCartTransaction;
    manualPromotionsList: ManualPromotion[] | null;
    inUsePromotions: number[];
    hasBaseTransaction: boolean;
    backorderUnits?: number;
}) {
    const { changePromotion } = useCartStore();
    const [currentPromotionID, updateCurrentPromotion] = useState(
        transaction.manualPromotionID
            ? transaction.manualPromotionID
            : !hasBaseTransaction
              ? 0
              : transaction.cartTransactionID !== null
                ? 0
                : null
    );
    // eslint-disable-next-line no-unused-vars
    const [message, formAction] = useFormState(changePromotionOnTransactions, null);

    const editDisabled =
        transaction.cartTransactionID === null && currentPromotionID === null && hasBaseTransaction;
    const backorderCases = backorderUnits / transaction.wholesaleUnitsPerCase;

    const showSelect = manualPromotionsList !== null;

    return (
        <div
            key={transaction.cartTransactionID}
            className={`flex w-full flex-col gap-4 
                ${backorderUnits > 0 ? "bg-amber-50" : ""} md:py-2 ${manualPromotionsList ? `md:hover:bg-slate-100` : ""}`}
        >
            <div className="my-auto grid h-full w-full grid-flow-col items-center justify-between ">
                {/* edit section */}
                <div className="flex flex-wrap gap-2 gap-y-2">
                    <div className="flex w-fit flex-row items-center gap-1 ">
                        <div
                            className={` ${editDisabled || transaction.cartTransactionID === null ? "pointer-events-none opacity-50" : ""} w-fit`}
                        >
                            <DeleteItemButton cartTrans={transaction} />
                        </div>
                        {showSelect ? (
                            <Select
                                value={currentPromotionID?.toString()}
                                onValueChange={async (value) => {
                                    updateCurrentPromotion(Number(value));

                                    if (transaction.cartTransactionID) {
                                        changePromotion(
                                            transaction.cartTransactionID!,
                                            Number(value)
                                        );
                                        const myAction = formAction.bind(null, {
                                            cartTransactionID: transaction.cartTransactionID!,
                                            promotionID: Number(value)
                                        });
                                        myAction();
                                    }
                                }}
                            >
                                <SelectTrigger className="h-8 w-40 border-gray-200 bg-white text-sm font-normal hover:bg-gray-100 focus:ring-1 focus:ring-gray-200">
                                    <SelectValue placeholder="Select Promotion" />
                                </SelectTrigger>
                                <SelectContent className="text-sm">
                                    {!hasBaseTransaction ||
                                    (transaction.cartTransactionID !== null &&
                                        transaction.manualPromotionID === null) ? (
                                        <SelectItem key={0} value={"0"}>
                                            Base Price
                                        </SelectItem>
                                    ) : null}
                                    {manualPromotionsList!
                                        .filter((promotion) => {
                                            return (
                                                !inUsePromotions.includes(promotion.promotionID) ||
                                                promotion.promotionID ==
                                                    transaction.manualPromotionID
                                            );
                                        })
                                        .map(
                                            (promotion) =>
                                                promotion && (
                                                    <SelectItem
                                                        key={promotion.promotionID}
                                                        value={promotion.promotionID.toString()}
                                                    >
                                                        {promotion.name}
                                                    </SelectItem>
                                                )
                                        )}
                                </SelectContent>
                            </Select>
                        ) : null}
                    </div>

                    {/* Case Unit Input */}
                    <div
                        className={`${
                            editDisabled ? "pointer-events-none opacity-50" : ""
                        } flex min-w-64 justify-start `}
                    >
                        <CaseUnitInput
                            key={transaction.cartTransactionID ?? 0}
                            productID={transaction.productID}
                            wholesaleUnitsPerCase={transaction.wholesaleUnitsPerCase}
                            sellByCaseOnly={transaction.sellByCaseOnly ?? false}
                            initialQuantity={{
                                cases: Math.floor(
                                    transaction.numUnits / transaction.wholesaleUnitsPerCase
                                ),
                                units: transaction.numUnits % transaction.wholesaleUnitsPerCase
                            }}
                            promotionID={currentPromotionID === 0 ? null : currentPromotionID}
                        />
                    </div>
                </div>

                {/* Price Display */}
                <div
                    className={`${editDisabled ? "pointer-events-none opacity-50" : ""} min-w-[80px] text-right`}
                >
                    <CartPriceDisplay
                        displayPrice={transaction.extPrice}
                        fullPriceDisplay={transaction.unitFullPrice * transaction.numUnits}
                        unitDiscount={transaction.discount}
                        unitPrice={transaction.unitPrice}
                        unitFullPrice={transaction.unitFullPrice}
                        numUnits={transaction.numUnits}
                        hasQuantityDiscount={transaction.quantityPromotionID !== null}
                    />
                </div>
            </div>

            {/* Backorder Badge */}
            {backorderUnits > 0 && (
                <Badge
                    variant="outline"
                    className="w-full border-amber-600 text-center text-amber-600 sm:w-auto sm:text-left"
                >
                    Backorder: {backorderCases.toFixed(1)} cases ({backorderUnits} units)
                </Badge>
            )}
        </div>
    );
}
