"use client";

import Link from "next/link";
import Logout from "../navbar/logout";

export default function LoginLink({ loggedIn }: { loggedIn: boolean }) {
    return !loggedIn ? (
        <Link
            href="/login"
            className="pr-2 text-sm font-medium text-neutral-500 hover:text-black dark:text-neutral-400 dark:hover:text-neutral-300"
        >
            Login
        </Link>
    ) : (
        <Logout />
    );
}
