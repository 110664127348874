"use client";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "@/components/shared/ui/select";
import { redirect, usePathname } from "next/navigation";

export default function StateSelector({ validStates }: { validStates: string[] }) {
    const urlSegments = usePathname().split("/");
    const state = urlSegments[1]?.toUpperCase() || "";

    return validStates.includes(state) ? (
        <Select onValueChange={(newState) => redirect(`/${newState}/shop`)} defaultValue={state}>
            <SelectTrigger>
                <SelectValue defaultValue={"Select a state"} />
            </SelectTrigger>
            <SelectContent className="w-36 p-2">
                <SelectGroup>
                    <SelectLabel>State</SelectLabel>
                    {validStates.map((state) => (
                        <SelectItem key={`${state}-select-item`} value={state}>
                            {state}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    ) : null;
}
