import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from "@/components/shared/ui/tooltip";
import { formatCurrency } from "@/lib/library";
import { BadgeDollarSign } from "lucide-react";

interface PriceDisplayProps {
    displayPrice: number;
    fullPriceDisplay: number;
    unitDiscount: number;
    unitPrice: number;
    unitFullPrice: number;
    numUnits: number;
    hasQuantityDiscount: boolean;
    className?: string;
    variant?: "default" | "medium" | "large";
    disableTooltip?: boolean;
}

const CartPriceDisplay = ({
    displayPrice,
    fullPriceDisplay,
    unitDiscount,
    unitPrice,
    unitFullPrice,
    numUnits,
    hasQuantityDiscount,
    className = "",
    variant = "default",
    disableTooltip = false
}: PriceDisplayProps) => {
    // const displayPrice = cartTrans.extPrice;
    // const fullPriceDisplay = price.unitFullPrice * cartTrans.numUnits;
    const displayDiscount = unitDiscount * numUnits;
    // const unitPrice = cartTrans.extPrice / cartTrans.numUnits;

    // const hasQuantityDiscount = !util.isNull(cartTrans.quantityPromotionID);
    const hasDiscount = Number(displayDiscount) > 0;

    const styles = {
        default: {
            container: "-space-y-1",
            icon: "h-3 w-3",
            price: "text-base font-semibold",
            fullPrice: "text-xs",
            discount: "text-xs font-medium"
        },
        medium: {
            container: "-space-y-0.5",
            icon: "h-3.5 w-3.5",
            price: "text-xl font-bold",
            fullPrice: "text-sm",
            discount: "text-sm font-medium"
        },
        large: {
            container: "-space-y-1",
            icon: "h-4 w-4",
            price: "text-3xl font-bold",
            fullPrice: "text-xl",
            discount: "text-sm font-medium"
        }
    }[variant];

    const PriceContent = () => (
        <div className={`flex flex-col ${styles.container} ${className} p-2`}>
            <div className="flex flex-wrap items-center justify-end gap-1">
                {hasQuantityDiscount && (
                    <BadgeDollarSign className={`${styles.icon} text-gold-500 shrink-0`} />
                )}
                <div className="grid grid-cols-1">
                    <div className="flex flex-row items-baseline gap-1">
                        <span className={styles.price}>{formatCurrency(displayPrice)}</span>
                    </div>
                </div>
            </div>
            {hasDiscount && (
                <div className="flex flex-col justify-between gap-2">
                    <span className={`${styles.fullPrice} text-gray-500 line-through`}>
                        {formatCurrency(fullPriceDisplay)}
                    </span>
                    <span className={`${styles.discount} text-green-600`}>
                        Save {formatCurrency(displayDiscount)}
                    </span>
                </div>
            )}
        </div>
    );

    const TooltipBreakdown = () => (
        <div className="space-y-2 p-1">
            <div className="font-medium">Price Breakdown</div>
            <div className="space-y-1 text-sm">
                <div className="space-y-1 border-b border-gray-200 pb-1">
                    <div className="flex justify-between gap-4">
                        <span>Original Price (per unit):</span>
                        <span>{formatCurrency(unitFullPrice)}</span>
                    </div>
                    {hasDiscount && (
                        <div className="flex flex-row justify-between gap-4 text-green-600">
                            <span>Discounted Price (per unit):</span>
                            <span>{formatCurrency(unitPrice)}</span>
                        </div>
                    )}
                </div>

                <div className="flex justify-between gap-4">
                    <span>Units:</span>
                    <span>× {numUnits}</span>
                </div>
                <div className="flex justify-between gap-4">
                    <span>Subtotal:</span>
                    <span>{formatCurrency(fullPriceDisplay)}</span>
                </div>
                <div className="flex justify-between gap-4 text-green-600">
                    <span>Discount:</span>
                    <span>- {formatCurrency(displayDiscount)}</span>
                </div>
                {hasQuantityDiscount && (
                    <div className="text-xs italic text-gray-500">*Includes quantity discount</div>
                )}
                <div className="space-y-1 border-t border-gray-200 pt-1">
                    <div className="flex justify-between gap-4 font-medium">
                        <span>Final Price:</span>
                        <span>{formatCurrency(displayPrice)}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    if (hasDiscount && !disableTooltip) {
        return (
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <div className="cursor-help">
                            <PriceContent />
                        </div>
                    </TooltipTrigger>
                    <TooltipContent side="top" align="start" className="w-72">
                        <TooltipBreakdown />
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        );
    }

    return <PriceContent />;
};

export default CartPriceDisplay;
