import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["AnnouncementCarousel"] */ "/app/src/components/features/home/announcements-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/features/home/customer-info.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/features/product/product-card/product-card-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/menu-promotions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/ui/image-with-fallback.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/shared/ui/tooltip.tsx");
