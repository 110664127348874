"use client";

import { ShopCartTransaction } from "@/lib/cart/cart-types";
import { ManualPromotion } from "@/lib/shop/shop-types";
import { memo, useMemo } from "react";
import CartTransactionDisplay from "./cart-transaction-display";

interface TransactionsListProps {
    transactions: ShopCartTransaction[];
    productID: number;
    productName: string;
    wholesaleUnitsPerCase: number;
    sellByCaseOnly: boolean;
    manualPromotionsList: ManualPromotion[] | null;
    thumbnailImageURL?: string;
}

// Memoized CartTransactionDisplay
const MemoizedCartTransactionDisplay = memo(CartTransactionDisplay);

// Empty transaction factory
const createEmptyTransaction = (
    productID: number,
    productName: string,
    wholesaleUnitsPerCase: number,
    sellByCaseOnly: boolean
): ShopCartTransaction => ({
    cartTransactionID: null,
    manualPromotionID: null,
    productID,
    productName,
    wholesaleUnitsPerCase,
    sellByCaseOnly,
    numUnits: 0,
    unitPrice: 0,
    unitFullPrice: 0,
    extPrice: 0,
    discount: 0,
    quantityPromotionID: null,
    productThumbnailImageURL: "",
    productState: ""
});

export function TransactionsList({
    transactions,
    productID,
    productName,
    wholesaleUnitsPerCase,
    sellByCaseOnly,
    manualPromotionsList
}: TransactionsListProps) {
    // Calculate derived values using useMemo
    const { sortedTransactions, hasBaseTransaction, inUsePromotions, maxTransactionID } =
        useMemo(() => {
            const inUsePromotions: number[] = [];
            let hasBaseTransaction = false;
            let maxTransactionID = 0;

            // Sort transactions
            const sortedTransactions = [...transactions].sort((a, b) => {
                if (a.manualPromotionID === null && b.manualPromotionID !== null) return -1;
                if (a.manualPromotionID !== null && b.manualPromotionID === null) return 1;
                return (a.cartTransactionID ?? 0) - (b.cartTransactionID ?? 0);
            });

            // Calculate derived values in a single pass
            for (const transaction of sortedTransactions) {
                if (transaction.manualPromotionID) {
                    inUsePromotions.push(transaction.manualPromotionID);
                } else {
                    hasBaseTransaction = true;
                }

                if (transaction.cartTransactionID) {
                    maxTransactionID = Math.max(maxTransactionID, transaction.cartTransactionID);
                }
            }

            return {
                sortedTransactions,
                hasBaseTransaction,
                inUsePromotions,
                maxTransactionID
            };
        }, [transactions]);

    // Determine if we should show the new transaction row
    const showAddNewTransaction = manualPromotionsList || !hasBaseTransaction;

    // Create empty transaction object if needed
    const emptyTransaction = useMemo(
        () =>
            showAddNewTransaction
                ? createEmptyTransaction(
                      productID,
                      productName,
                      wholesaleUnitsPerCase,
                      sellByCaseOnly
                  )
                : null,
        [showAddNewTransaction, productID, productName, wholesaleUnitsPerCase, sellByCaseOnly]
    );

    return (
        <div className="w-full space-y-1">
            {sortedTransactions.map((transaction) => (
                <MemoizedCartTransactionDisplay
                    transaction={transaction}
                    manualPromotionsList={manualPromotionsList}
                    key={`${transaction.cartTransactionID}-${transaction.numUnits}`}
                    inUsePromotions={inUsePromotions}
                    hasBaseTransaction={true}
                    backorderUnits={transaction.backorderNumUnits}
                />
            ))}
            {emptyTransaction && (
                <MemoizedCartTransactionDisplay
                    key={`new-transaction-${maxTransactionID}`}
                    manualPromotionsList={manualPromotionsList}
                    transaction={emptyTransaction}
                    inUsePromotions={inUsePromotions}
                    backorderUnits={0}
                    hasBaseTransaction={hasBaseTransaction}
                />
            )}
        </div>
    );
}
