"use client";

import { Button } from "@/components/shared/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/shared/ui/card";
import { Separator } from "@/components/shared/ui/seperator";
import { ShopCustomer } from "@/lib/customers/customer-types";
import { useToast } from "@/state-management/hooks/use-toast";
import { Heart } from "lucide-react";
import { useState } from "react";
import { updateCustomerFavorite } from "../customer-select/save-favorite-action";

type CustomerInfoProps = {
    customer: ShopCustomer;
    userID: number;
    deliveryDates?: string[];
};

export default function CustomerInfo({ customer, userID, deliveryDates }: CustomerInfoProps) {
    const { toast } = useToast();
    const [isFavorite, setIsFavorite] = useState<boolean>(customer.isFavorite);

    const handleFavoriteClick = async (customerId: number, isFavorite: boolean) => {
        try {
            setIsFavorite(isFavorite);
            const result = await updateCustomerFavorite(userID, customerId, isFavorite);

            if (!result.success) {
                setIsFavorite(!isFavorite);
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "Failed to update favorite status"
                });
            } else {
                toast({
                    variant: "default",
                    title: "Success",
                    description: "Favorite customers updated"
                });
            }
        } catch (error) {
            setIsFavorite(!isFavorite);
            toast({
                variant: "destructive",
                title: "Error",
                description: "Failed to update favorite status"
            });
        }
    };

    return (
        <Card className="mb-8">
            <CardHeader
                className={`flex w-full flex-row items-center justify-between space-y-0 pb-4 ${customer.isOnHold ? "bg-red-100" : ""}`}
            >
                <div className="w-full">
                    {customer.isOnHold ? (
                        <div className="w-full text-center text-xl font-bold underline">
                            Your account is currently on credit hold. Please contact your KSS
                            Collection Agent.
                        </div>
                    ) : null}
                </div>
            </CardHeader>
            <CardContent>
                <div className="flex flex-row justify-around gap-8">
                    <CardTitle className="text-baseline flex flex-row text-3xl font-bold">
                        <Button
                            variant="ghost"
                            size="lg"
                            onClick={() => handleFavoriteClick(customer.customerID, !isFavorite)}
                            aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}
                        >
                            <Heart
                                className={`h-24 w-24 ${
                                    isFavorite ? "fill-red-500 text-red-500" : "text-gray-400"
                                }`}
                            />
                        </Button>
                        <p className="mt-2">
                            <span className="mr-2 text-lg font-semibold text-gray-600">
                                ({customer.customerID})
                            </span>
                            {customer.customerName}
                        </p>
                    </CardTitle>
                    <div className="space-y-1">
                        <p className="text-sm font-medium text-gray-500">License Number</p>
                        <p className="text-base">{customer.licenseNumber}</p>
                    </div>

                    <div className="space-y-1">
                        <p className="text-sm font-medium text-gray-500">State</p>
                        <p className="text-base">{customer.state}</p>
                    </div>

                    <div className="space-y-1">
                        <p className="text-sm font-medium text-gray-500">Address</p>
                        <p className="text-base">{customer.address}</p>
                    </div>

                    {customer.pendingCartQuantity !== undefined &&
                        customer.pendingCartQuantity > 0 && (
                            <div className="rounded-lg bg-blue-50 p-4">
                                <p className="font-medium text-blue-700">
                                    {customer.pendingCartQuantity} items in pending cart
                                </p>
                            </div>
                        )}
                </div>

                {deliveryDates && deliveryDates.length > 0 && (
                    <>
                        <Separator className="my-4" />
                        <div className="space-y-3">
                            <h3 className="font-semibold text-gray-900">Upcoming Deliveries</h3>
                            <div className="grid grid-cols-2 gap-2">
                                {deliveryDates.map((date, index) => (
                                    <div
                                        key={index}
                                        className="rounded-md bg-gray-50 p-2 text-sm text-gray-700"
                                    >
                                        {new Date(date).toLocaleDateString("en-US", {
                                            weekday: "short",
                                            month: "short",
                                            day: "numeric"
                                        })}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </CardContent>
        </Card>
    );
}
