"use client";

import { Button } from "@/components/shared/ui/button";
import { useCurrentCustomer } from "@/state-management/context/current-customer-context";
import { useCartStore } from "@/state-management/stores/cart-store";
import { Dialog, Transition } from "@headlessui/react";
import Link from "next/link";
import { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import TransactionsListWithHeader from "../cart-edit/transactions-list-with-header";
import { findOrCreateCartAndSetCookie } from "./actions";
import CloseCart from "./close-cart";
import OpenCart from "./open-cart";

// Memoized Cart Header
const CartHeader = memo(function CartHeader({
    customerName,
    customerID,
    onClose
}: {
    customerName?: string;
    customerID?: number;
    onClose: () => void;
}) {
    return (
        <div className="flex items-center justify-between pb-4">
            <p className="text-lg font-semibold">
                Cart for ({customerID}) {customerName}
            </p>
            <button aria-label="Close cart" onClick={onClose}>
                <CloseCart />
            </button>
        </div>
    );
});

// Memoized Empty Cart Message
const EmptyCartMessage = memo(function EmptyCartMessage() {
    return <p className="mt-6 text-center text-2xl font-bold">Your cart is empty.</p>;
});

// Memoized Cart Total
const CartTotal = memo(function CartTotal({ total }: { total: number }) {
    const formattedTotal = useMemo(() => {
        return new Intl.NumberFormat(undefined, {
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol"
        }).format(total);
    }, [total]);

    return (
        <div className="mb-3 flex items-center justify-between border-t border-neutral-200 pb-1 pt-3 dark:border-neutral-700">
            <p>Total</p>
            <p>{formattedTotal}</p>
        </div>
    );
});

// Memoized Checkout Button
const CheckoutButton = memo(function CheckoutButton({ onClose }: { onClose: () => void }) {
    return (
        <Link href="/checkout" onClick={onClose}>
            <Button
                className="block w-full p-3 text-center text-sm font-medium text-white opacity-90 hover:opacity-100"
                type="button"
            >
                Checkout
            </Button>
        </Link>
    );
});

export default function CartModal() {
    const [isOpen, setIsOpen] = useState(false);
    const { customer } = useCurrentCustomer();

    const { cart, skuCount } = useCartStore(
        useShallow((state) => ({
            cart: state.cart,
            skuCount: state.cart ? new Set(state.cart.transactions.map((t) => t.productID)).size : 0
        }))
    );

    const openCart = useCallback(() => setIsOpen(true), []);
    const closeCart = useCallback(() => setIsOpen(false), []);

    useEffect(() => {
        if (!cart) {
            findOrCreateCartAndSetCookie();
        }
    }, [cart]);

    return (
        <>
            <button aria-label="Open cart" onClick={openCart}>
                <OpenCart quantity={skuCount} />
            </button>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog onClose={closeCart} className="relative z-50">
                    <Transition.Child
                        as={Fragment}
                        enter="transition-all ease-in duration-200"
                        enterFrom="opacity-0 backdrop-blur-none"
                        enterTo="opacity-100 backdrop-blur-[.5px]"
                        leave="transition-all ease-in-out duration-150"
                        leaveFrom="opacity-100 backdrop-blur-[.5px]"
                        leaveTo="opacity-0 backdrop-blur-none"
                    >
                        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="flex h-full items-start justify-end">
                            <Transition.Child
                                as={Fragment}
                                enter="transition-all ease-in-out duration-200"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition-all ease-in-out duration-150"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="flex h-full w-full flex-col border-l border-neutral-200 bg-white text-black shadow-xl dark:border-neutral-700 dark:bg-black/80 dark:text-white md:w-[490px]">
                                    <div className="p-6">
                                        <CartHeader
                                            customerName={customer?.customerName}
                                            customerID={customer?.customerID}
                                            onClose={closeCart}
                                        />
                                    </div>

                                    <div className="flex-1 overflow-y-auto px-6">
                                        {!cart || cart.transactions.length === 0 ? (
                                            <EmptyCartMessage />
                                        ) : (
                                            <TransactionsListWithHeader
                                                transactions={cart.transactions}
                                                cartInventory={null}
                                                productPromotions={cart.promotionsProducts || null}
                                            />
                                        )}
                                    </div>

                                    <div className="border-t border-neutral-200 bg-white px-6 py-4 dark:border-neutral-700">
                                        <CartTotal total={cart?.totalExtPrice ?? 0} />
                                        <CheckoutButton onClose={closeCart} />
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
