"use client";

import { Card, CardContent } from "@/components/shared/ui/card";
import { ShopAnnouncement } from "@/lib/shop/shop-types";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";

("announcements");

type AnnouncementCarouselProps = {
    announcements: ShopAnnouncement[];
};

export function AnnouncementCarousel({ announcements }: AnnouncementCarouselProps) {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prev) => (prev + 1) % announcements.length);
        }, 5000);
        return () => clearInterval(timer);
    }, [announcements.length]);

    if (announcements.length === 0) {
        return null;
    }

    const navigate = (direction: "prev" | "next") => {
        if (direction === "prev") {
            setCurrentIndex((prev) => (prev - 1 + announcements.length) % announcements.length);
        } else {
            setCurrentIndex((prev) => (prev + 1) % announcements.length);
        }
    };

    const currentAnnouncement = announcements[currentIndex];

    if (!currentAnnouncement) {
        return null;
    }

    return (
        <div className="relative mx-auto w-full max-w-4xl">
            <Card className="bg-white shadow-lg">
                <CardContent className="p-6">
                    <div className="flex items-center justify-between">
                        <button
                            onClick={() => navigate("prev")}
                            className="rounded-full p-2 hover:bg-gray-100"
                        >
                            <ChevronLeft className="h-6 w-6" />
                        </button>
                        <div className="mx-4 flex-1">
                            <h3 className="mb-2 text-xl font-bold">{currentAnnouncement.title}</h3>
                            <p className="text-gray-600">{currentAnnouncement.content}</p>
                            {currentAnnouncement.linkUrl && (
                                <a
                                    href={currentAnnouncement.linkUrl}
                                    className="mt-2 inline-block text-blue-600 hover:underline"
                                >
                                    Learn More →
                                </a>
                            )}
                        </div>
                        <button
                            onClick={() => navigate("next")}
                            className="rounded-full p-2 hover:bg-gray-100"
                        >
                            <ChevronRight className="h-6 w-6" />
                        </button>
                    </div>
                </CardContent>
            </Card>
            <div className="mt-4 flex justify-center gap-2">
                {announcements.map((_, idx) => (
                    <button
                        key={idx}
                        className={`h-2 w-2 rounded-full ${
                            idx === currentIndex ? "bg-blue-600" : "bg-gray-300"
                        }`}
                        onClick={() => setCurrentIndex(idx)}
                    />
                ))}
            </div>
        </div>
    );
}
