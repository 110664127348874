"use client";

import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger
} from "@/components/shared/ui/collapsible";
import { GroupedTransactions, ShopCartTransaction } from "@/lib/cart/cart-types";
import { ManualPromotion, ShopInventory } from "@/lib/shop/shop-types";
import { ChevronDown, ChevronUp } from "lucide-react";
import { memo, useMemo, useState } from "react";
import { InventoryWarnings } from "./inventory-warning";
import { ProductHeader } from "./product-header";
import { TransactionsList } from "./transactions-list";

const MemoizedTransactionsList = memo(TransactionsList);

const useGroupedTransactions = (
    transactions: ShopCartTransaction[],
    cartInventory: { [key: number]: ShopInventory } | null
): GroupedTransactions => {
    return useMemo(() => {
        return transactions.reduce<GroupedTransactions>((acc, transaction) => {
            const productID = transaction.productID;

            if (!acc[productID]) {
                acc[productID] = {
                    productID: transaction.productID,
                    productName: transaction.productName,
                    thumbnailImageURL: transaction.productThumbnailImageURL,
                    wholesaleUnitsPerCase: transaction.wholesaleUnitsPerCase,
                    sellByCaseOnly: transaction.sellByCaseOnly,
                    transactions: [],
                    totalUnits: 0,
                    totalFullPrice: 0,
                    totalExtPrice: 0,
                    totalDiscount: 0,
                    inUsePromotions: [],
                    backorderUnits: 0,
                    hasBaseTransaction: false,
                    state: transaction.productState
                };
            }

            acc[productID]!.transactions.push(transaction);
            acc[productID]!.totalUnits += Number(transaction.numUnits);
            acc[productID]!.totalExtPrice += Number(transaction.extPrice);
            acc[productID]!.totalFullPrice +=
                (Number(transaction.unitPrice) + Number(transaction.discount)) *
                transaction.numUnits;
            acc[productID]!.totalDiscount += Number(transaction.discount * transaction.numUnits);

            if (cartInventory && cartInventory[productID]) {
                const availableInventory = cartInventory[productID]!.availableUnits ?? 0;
                acc[productID]!.backorderUnits = Math.max(
                    0,
                    acc[productID]!.totalUnits - availableInventory
                );
            }

            if (transaction.manualPromotionID) {
                acc[productID]!.inUsePromotions.push(transaction.manualPromotionID);
            } else {
                acc[productID]!.hasBaseTransaction = true;
            }

            return acc;
        }, {});
    }, [transactions, cartInventory]);
};

const ProductGroup = memo(function ProductGroup({
    productID,
    group,
    cartInventory,
    productPromotions
}: {
    productID: string;
    group: GroupedTransactions[keyof GroupedTransactions];
    cartInventory: { [key: number]: ShopInventory } | null;
    productPromotions: { [key: number]: ManualPromotion[] } | null;
}) {
    const [isOpen, setIsOpen] = useState(false);

    const inventoryError = useMemo(() => {
        if (!cartInventory || !cartInventory[Number(productID)]) return null;

        const inventory = cartInventory[Number(productID)];
        if (!inventory) return null;

        if (group.totalUnits > inventory.availableUnits) {
            return {
                productId: Number(productID),
                message: `Only ${Number(inventory.availableUnits).toFixed(0)} units available (${Number(
                    group.totalUnits - inventory.availableUnits
                ).toFixed(0)} units will be placed on backorder)`,
                availableUnits: inventory.availableUnits
            };
        }
        return null;
    }, [cartInventory, productID, group.totalUnits]);

    const manualPromotionsList = useMemo(() => {
        if (!productPromotions) return null;
        const promotions = productPromotions[group.productID];
        return promotions ? promotions : [];
    }, [productPromotions, group.productID]);

    return (
        <div className="space-y-4">
            {inventoryError && (
                <InventoryWarnings
                    inventoryErrors={[inventoryError]}
                    productNames={{
                        [productID]: group.productName
                    }}
                />
            )}
            <Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-full">
                <ProductHeader group={group} />

                <CollapsibleTrigger className="flex w-full flex-row items-center justify-between border-b bg-slate-600 px-4 text-sm text-white">
                    <span>
                        {group.transactions.length} Transaction
                        {group.transactions.length > 1 ? "s" : ""}
                    </span>
                    <span>Click to edit</span>
                    {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                </CollapsibleTrigger>

                <CollapsibleContent className="w-full border-b pl-6 pr-3">
                    <MemoizedTransactionsList
                        transactions={group.transactions}
                        productID={group.productID}
                        productName={group.productName}
                        wholesaleUnitsPerCase={group.wholesaleUnitsPerCase}
                        sellByCaseOnly={group.sellByCaseOnly}
                        manualPromotionsList={manualPromotionsList}
                    />
                </CollapsibleContent>
            </Collapsible>
        </div>
    );
});

export default function TransactionsListWithHeader({
    transactions,
    cartInventory,
    productPromotions
}: {
    transactions: ShopCartTransaction[];
    cartInventory: {
        [key: number]: ShopInventory;
    } | null;
    productPromotions: { [key: number]: ManualPromotion[] } | null;
}) {
    const transactionGroups = useGroupedTransactions(transactions, cartInventory);

    return (
        <div className="space-y-8">
            {Object.entries(transactionGroups).map(([productID, group]) => {
                return (
                    <ProductGroup
                        key={productID}
                        productID={productID}
                        group={group}
                        cartInventory={cartInventory}
                        productPromotions={productPromotions}
                    />
                );
            })}
        </div>
    );
}
