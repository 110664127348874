import CartPriceDisplay from "@/components/features/cart-edit/cart-price-display";
import ImageWithFallback from "@/components/shared/ui/image-with-fallback";
import { TransactionGroup } from "@/lib/cart/cart-types";
import { Cannabis } from "lucide-react";
import Link from "next/link";

interface ProductHeaderProps {
    group: TransactionGroup;
}

export function ProductHeader({ group }: ProductHeaderProps) {
    return (
        <div className="w-full border-b">
            <div className="flex w-full flex-row items-start gap-4">
                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md bg-gray-100 sm:h-20 sm:w-20">
                    <Link href={`/${group.state}/product/${group.productID}`}>
                        {group.thumbnailImageURL ? (
                            <ImageWithFallback
                                unoptimized
                                className="border-white-15 h-full w-full border object-contain"
                                primarySrc={group.thumbnailImageURL}
                                fallbackSrc={null}
                                alt={group.productName}
                                fill
                            />
                        ) : (
                            <div className="flex h-full w-full items-center justify-center">
                                <Cannabis className="h-8 w-8 text-gray-400" />
                            </div>
                        )}
                    </Link>
                </div>

                <div className="flex w-full flex-1 flex-col gap-4 sm:flex-row">
                    <div className="min-w-0 flex-1">
                        <Link href={`/${group.state}/product/${group.productID}`}>
                            <h3 className="break-words text-lg font-medium text-gray-900">
                                {group.productName}
                            </h3>
                        </Link>
                    </div>

                    <div className="flex w-full flex-row justify-between gap-2 sm:w-auto sm:flex-col sm:items-end">
                        <div className="text-right">
                            <CartPriceDisplay
                                variant="medium"
                                disableTooltip={true}
                                displayPrice={group.totalExtPrice}
                                fullPriceDisplay={group.totalFullPrice}
                                unitDiscount={group.totalDiscount / group.totalUnits}
                                unitPrice={0}
                                unitFullPrice={0}
                                numUnits={group.totalUnits}
                                hasQuantityDiscount={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2 flex flex-row justify-between text-sm text-gray-600">
                <div className="flex flex-wrap gap-x-4">
                    <span>
                        Total Cases: {Math.floor(group.totalUnits / group.wholesaleUnitsPerCase)}
                    </span>
                    <span>Total Units: {group.totalUnits}</span>
                </div>
                {group.backorderUnits > 0 && (
                    <div className="mt-1">
                        <span className="text-amber-600">
                            Backorder Units: {group.backorderUnits}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}
