import { cn } from "@/lib/library";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

export type BadgeVariantType = keyof typeof variants.variant;

const variants = {
    variant: {
        default: "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        defaultNoHover: "border-transparent bg-primary text-primary-foreground",
        secondary:
            "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        secondaryNoHover: "border-transparent bg-secondary text-secondary-foreground",
        destructive:
            "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        destructiveNoHover: "border-transparent bg-destructive text-destructive-foreground",
        outline: "text-foreground",
        transparent: "border-transparent text-foreground"
    }
};

const badgeVariants = cva(
    "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
    {
        variants,
        defaultVariants: {
            variant: "default"
        }
    }
);

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
    return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
