"use client";

import { CART_ID_COOKIE_NAME } from "@/lib/constants";
import { signOut } from "next-auth/react";

export default function Logout({ className }: { className?: string }) {
    return (
        <div
            aria-label="Log Out"
            onClick={() => {
                document.cookie = `${CART_ID_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                localStorage.removeItem("currentCustomer");
                signOut();
            }}
            className={`${className} cursor-pointer`}
        >
            Logout
        </div>
    );
}
